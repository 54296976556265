<template>
    <v-sheet class="custom-border border pa-4">
        <v-dialog v-model="dialog" persistent width="370">
            <v-card width="370" class="overflow-x-hidden">
                <section class="d-flex justify-end">
                    <v-btn icon @click="() => {
                        remove()
                        dialog = false
                    }">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </section>
                <v-container fluid class="text-center primary-font fw600 f16" >
                    Upload proof of payment
                    <v-container
                        class="bulk-border mt-2 d-flex flex-column justify-center"
                        @dragover="dragover"
                        @dragleave="dragleave"
                        @drop="drop"
                    >
                        <div class="d-flex flex-column py-3">
                            <v-icon color="rgba(63, 93, 208, 0.12)" size="68"
                                >mdi-cloud-upload-outline</v-icon
                            >
                            <div class="secondary--text f12 poppins">
                                <a
                                href="#"
                                @click="$refs.file.click()"
                                class="primary--text text-decoration-none"
                                >
                                Browse
                                </a>
                                or drag file here 
                                <div>
                                    ( .pdf, .docx, .doc, .png, .jpg, .jpeg )
                                </div>
                            </div>
                        </div>
                    </v-container>
                    <alert 
                        v-if="msg.show"
                        :show = "msg.show"
                        :text = "msg.text"
                        :type = "msg.type"
                    />
                    <v-container
                        v-if="proof_of_payment"
                        class="d-flex justify-space-between align-center my-3">
                        <div >
                            <div class="roboto fw600 f12" style="margin-bottom: -8px">
                                {{ proof_of_payment.name }}
                            </div>
                            <span class="roboto fw500 f10 secondary--text">
                                {{ (proof_of_payment.size / 1000).toFixed(2) }} KB
                            </span>
                        </div>
                        <v-spacer />
                        <v-progress-linear
                            v-if="loading4"
                            indeterminate
                            color="success"
                            height="7"
                            style="width: 120px"
                            rounded
                        />
                        <v-btn icon small @click="remove()">
                            <v-icon size="18" color="danger-1">mdi-close</v-icon>
                        </v-btn>
                    </v-container>
                </v-container>
                <section class="d-flex flex-row justify-space-evenly pa-3">
                    <v-spacer />
                    <v-btn small outlined dense color="success" :disabled="!loading ? !proof_of_payment : true" @click="submitProofOfPayment()" :loading="submitLoading">
                        SUBMIT
                    </v-btn>
                </section>
                <input
                    type="file"
                    ref="file"
                    class="d-none"
                    accept=".pdf,.docx,.doc,.png,.jpg,.jpeg"
                    @change="accept"
                />
            </v-card>
        </v-dialog>
        <section class="d-flex flex-wrap justify-space-between">
            <div class="poppins fw600 f18 secondary-1--text pa-3">Transaction History</div>
            <FilterMenu 
                :initialFilter="filter"
                @resetFilters="clearFilters()"
                @applyFilters="() => {page = 1, getTransactions()}">
                <section>
                    <FormSelectObject
                        v-if="tab === 0"
                        :label="'MODE OF PAYMENT'"
                        :items="mode_of_payment"
                        :value.sync="filter.mode_of_payment"
                        class="mb-3"
                    />

                    <FormSelectObject
                        v-if="tab === 0"
                        :label="'TYPE OF PAYMENT'"
                        :items="filter.mode_of_payment === '' ? type_of_payment : (filter.mode_of_payment === 'online' ? online_type_of_payment : offline_type_of_payment)"
                        :value.sync="filter.type_of_payment"
                        :disabled="filter.mode_of_payment == 'online'"
                        class="mb-3"
                    />

                    <FormSelectObject
                        v-if="tab === 0"
                        :label="'PAYMENT STATUS'"
                        :items="payment_status"
                        :value.sync="filter.invoice_status"
                        class="mb-3"
                    />

                    <div class="mt-3">
                        <FormLabel class="mb-1" :label="'TRANSACTION DATE (YYYY-MM-DD)'" />
                    </div>
                    <label class="caption">FROM:</label>
                    <FormDatePicker 
                        :val="filter.date_from"
                        :max="maxDate"
                        class_="col-12 mb-3"
                        @apply="(e) => {
                            filter.date_from = e
                            minDate = e
                        }"/>

                    <label class="caption">TO:</label>
                    <FormDatePicker 
                        :val="filter.date_to"
                        :min="minDate"
                        class_="col-12 mb-3"
                        @apply="(e) => {
                            filter.date_to = e
                            maxDate = e
                        }"/>
                </section>
            </FilterMenu>
            
        </section>
        <v-tabs v-model="tab" show-arrows class="px-3">
            <v-tab
                v-for="(item, i) in items"
                :key="i"
                class="text-capitalize poppins f14 fw600"
                active-class="primary--text"
            >
                <span>
                {{ item }}
                </span>
            </v-tab>
        </v-tabs>
        <v-data-table
            :headers="tab === 0 ? transaction_history_tbl_paid : transaction_history_tbl_free"
            class="poppins"
            :items="invoices"
            :loading="loading"
            hide-default-footer
            :items-per-page.sync="itemsPerPage">
            <template v-slot:item.mode_of_payment="{ item }" >
                <span class="text-uppercase">
                    {{ item.mode_of_payment }}
                </span>
            </template>
            <template v-slot:item.date="{ item }">
                {{  $dateFormat.dayDateTime(item.date) }}
            </template>
            <template v-slot:item.date_enrolled="{ item }">
                {{  item.date_enrolled === '' ? '' : $dateFormat.dayDateTime(item.date_enrolled) }}
            </template>
            <template v-slot:item.receipt="{ item }">
                <a
                    v-if="item.image && item.mode_of_payment === 'offline'"
                    :href="item.image.url"
                    class="primary--text text-decoration-none f12"
                    target="_blank"
                    >
                    <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                    View
                </a>
                <v-btn v-if="item.mode_of_payment === 'online' && item.status != 4" outlined color="secondary-1" dense class="pa-2 f12" @click="requestOR(item)" small> Request OR </v-btn>
            </template>
            <template v-slot:item.status="{ item }">
                <v-alert v-if="item.status === 1" dense color="#7BC14533" class="success--text ma-auto f14">SUCCESS</v-alert>
                <v-alert v-if="item.status === 2" dense color="#BDBDBD33" class="secondary-1--text ma-auto f14">PENDING</v-alert>
                <v-alert v-if="item.status === 3" dense color="#B5221033" class="error--text ma-auto f14">DISQUALIFIED</v-alert>
                <v-alert v-if="item.status === 4" dense class="secondary-2--text ma-auto f14">CANCELLED</v-alert>
            </template>
            <template v-slot:item.nomination_form="{ item }">
                <a
                    v-if="item.nomination_form && item.nomination_form.url !== ''"
                    :href="item.nomination_form.url"
                    class="primary--text text-decoration-none f12"
                    target="_blank"
                    >
                    <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                    View
                </a>
            </template>
            <template v-slot:item.type_of_payment="{ item }" >
                <span class="text-capitalize">
                    {{ item.type_of_payment.replace('_', ' ') }}
                </span>
            </template>
            <template v-slot:item.proof_of_payment="{ item }">
                <a
                    v-if="item.proof_of_payment && item.proof_of_payment.url !== ''"
                    :href="item.proof_of_payment.url"
                    class="primary--text text-decoration-none f12"
                    target="_blank"
                    >
                    <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                    View
                </a>
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn block dense v-if="item.status === 1" color="success" text class="f12" @click="$router.push({name: 'User Course Details', params: {id: item.uuid}})">
                    <v-icon class="mr-1" dense>
                        mdi-eye-outline
                    </v-icon>
                    View Course
                </v-btn>
                <v-btn block :loading="loading1" v-if="item.status === 3" color="warning" text class="f12" dense @click="addToList(item.course_id)">
                    <v-icon class="mr-1" dense>
                        mdi-text-box-outline
                    </v-icon>
                    Add to my List
                </v-btn>
                <div v-if="item.status === 2 && !item.nomination_form && item.mode_of_payment === 'online'" class="f12 secondary-2--text" dense>
                    <v-icon class="mr-1" dense>
                        mdi-clock-outline
                    </v-icon>
                    For Approval
                </div>
                <div v-if="item.status === 2 && !item.nomination_form && item.mode_of_payment === 'offline'" class="f12 secondary-2--text" dense>
                    <!-- <v-icon class="mr-1" dense>
                        mdi-clock-outline
                    </v-icon> -->
                    For Approval of Admin
                </div>
                <div v-if="item.status === 2 && item.nomination_form && item.proof_of_payment && item.mode_of_payment === 'online'" class="f12 secondary-2--text" dense>
                    <v-icon class="mr-1" dense>
                        mdi-clock-outline
                    </v-icon>
                    For Approval of Admin
                </div>
                <v-btn dense block :loading="loading2" v-if="item.status === 2 && item.nomination_form && !item.proof_of_payment" color="error" text class="f12" @click="() => { transaction = item, dialog = true }">
                    <v-icon class="mr-1" dense>
                        mdi-upload-outline
                    </v-icon>
                    Upload Proof of Payment
                </v-btn>
                
            </template>
            <template v-slot:item.cancel="{ item }">
                <v-btn icon dense text v-if="item.status === 2" class="f12 error--text" @click="() => { cancelDialog = true, selected = item }">
                    <v-icon color="error" small dense>
                        mdi-close
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <FormPagination 
            :pageCount="pageCount" 
            :page="page"
            :paginate="paginate"
            @page="(e) => { page = e, getTransactions()}" 
            @paginate="(e) => { paginate = e, page = 1, getTransactions()}"/>

        <CancelTransactionDialog 
            :dialog="cancelDialog"
            :item="selected"
            @close="() => { cancelDialog = false, getTransactions() }"
        />
        
    </v-sheet>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { transaction_history_tbl_paid, transaction_history_tbl_free } from '@/constants/tblheaders/transactionhistory';
import { mode_of_payment, offline_type_of_payment, online_type_of_payment, payment_status } from '@/constants/payment'
import SingleUploadDialog from '@/components/reusable/forms/SingleUploadDialog.vue';
import CancelTransactionDialog from '@/components/user/course/tocart/CancelTransactionDialog.vue';

export default {
    components: {
        SingleUploadDialog,
        CancelTransactionDialog
    },
    data: () => ({
        items: ['PAID', 'FREE'],
        transaction_history_tbl_paid,
        transaction_history_tbl_free,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        submitLoading: false,
        dialog: false,
        transaction: null,
        proof_of_payment: null,
        pageCount: 1,
        page: 1,
        paginate: '10',
        tab: 0,
        msg: {
            show: false,
            type: '',
            text: ''
        },
        selected: null,
        cancelDialog: false,
        filter: {
            date_from: '',
            date_to: '',
            mode_of_payment: '',
            type_of_payment: '',
            invoice_status: '',
        },
        minDate: '',
        maxDate: '',
        totalCount: 0,
        mode_of_payment,
        offline_type_of_payment,
        online_type_of_payment,
        payment_status
    }),
    methods: {
        ...mapMutations(['alertMutation']),
        ...mapActions('usr', ['getTransactionHistory', 'addToCart', 'uploadProofOfPayment', 'courseProofOfPaymentAction', 'requestORCSC']),

        clearFilters(){
            this.filter = {
                date_from: '',
                date_from: '',
                date_to: '',
                mode_of_payment: '',
                type_of_payment: '',
                invoice_status: '',
            }

            this.minDate = ''
            this.maxDate = ''
        },

        addToList(id) {
            this.loading1 = true
            this.addToCart({course_id: [id]}).then(() => {
                this.loading1 = false
                this.alertMutation({
                    show: true,
                    text: 'Added to List!',
                    type: "success"
                })
            })
        },

        remove(){
            this.proof_of_payment = ''
            this.$refs.file.value = ''
        },

        dragover(e) {
            e.preventDefault();
            e.currentTarget.classList.add("bulk-border-drag");
        },

        dragleave(e) {
            e.preventDefault();
            e.currentTarget.classList.remove("bulk-border-drag");
        },

        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.accept();
            e.currentTarget.classList.remove("bulk-border-drag");
            // console.log(this.$refs.file.files);
        },

        submitProofOfPayment() {

            this.submitLoading = true

            this.msg = {
                show: false,
                type: '',
                text: ''
            }

            let _form = new FormData()

            _form.append('invoice_id', this.transaction.id)
            _form.append('proof_of_payment', this.proof_of_payment)

            this.courseProofOfPaymentAction(_form).then(() => {
                this.dialog = false
                this.alertMutation({
                    show: true,
                    text: 'Proof of payment uploading success!',
                    type: "success"
                })
                this.getTransactions()
            }).catch(res => {
                if(this.errors.proof_of_payment) {
                    this.msg = {
                        show: true,
                        type: 'error',
                        text: this.errors.proof_of_payment[0]
                    }
                } else {
                    this.msg = {
                        show: true,
                        type: 'error',
                        text: res.message
                    }
                }
            }).finally(() => {
                this.submitLoading = false
            })
        },

        accept() {
            this.msg = {
                show: false,
                type: '',
                text: ''
            }

            this.proof_of_payment = this.$refs.file.files[0]
            this.loading4 = true;
            setTimeout(() => {
                this.loading4 = false;
            }, 3000);
            
            this.$refs.file.value = ''            
        },
        

        submitFile(e) {
            this.loading3 = true

            let _formData = new FormData();

            _formData.append("invoice_id", this.transaction.nomination_form.invoice_id);
            _formData.append("course_id", this.transaction.course_id);
            _formData.append("official_receipt_no", this.transaction.official_receipt_no);
            _formData.append("_method", "PUT");

            if(e) {
                _formData.append("image", e);
            }

            this.uploadProofOfPayment({invoice_id: this.transaction.nomination_form.invoice_id, form: _formData}).then(() => {
                this.loading3 = false
                this.transaction = null
                this.dialog = false
                this.alertMutation({
                    show: true,
                    text: 'Proof of payment successfully uploaded.',
                    type: "success"
                })
                this.getTransactions()
            }).catch(e => {
                this.alertMutation({
                    show: true,
                    text: 'Something went wrong in uploading.',
                    type: "error"
                })
                this.loading3 = false
            })
        },

        getTransactions(){
            this.loading = true
            let _filter = {}
            if(this.tab === 0) {
                _filter = this.filter
            } else {
                _filter = {
                    date_to: this.filter.date_to,
                    date_from: this.filter.date_from
                }
            }
            this.getTransactionHistory({ 
                page: this.page, 
                paginate: Number(this.paginate), 
                is_free: this.tab,
                ..._filter
            }).then((res) => {
                this.page = res.current_page
                this.paginate = String(res.per_page)
                this.pageCount = res.last_page
                this.totalCount = res.total
            }).finally(() => {
                this.loading = false
            })
        },

        async requestOR(item) {
            this.requestORLoading = true
            this.invoiceIDForOR = item.invoice_id
            this.requestORCSC({ 
                course_uuid: item.course_uuid,
                user_id: item.user_id,
                email: item.email,
                reference_id: item.reference_id,
            }).then((res) => {
                this.alertMutation({
                    show: true,
                    text: "Requested OR successfully generated!",
                    type: "success"
                })
                this.$exportToPDF(`${item.name}`, res)
            }).catch(err => {
                if (err.response && err.response.status === 404) {
                    this.alertMutation({
                        show: true,
                        text: 'Official Receipt not available.',
                        type: "error"
                    })
                } else {
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong',
                        type: "error"
                    })
                }
            })
        },
    },
    mounted() {
        this.getTransactions()
    },
    computed: {
        ...mapState('usr', {
            invoices: (state) => state.transaction_history
        }),

        itemsPerPage(){
            return Number(this.paginate)
        }
    },
    watch: {
        tab(val) {
            this.getTransactions();
        },
        
        filter: {
            handler(val) {
                if(val.mode_of_payment === 'online') {
                    this.filter.type_of_payment = 'linkbiz_portal'
                }
            },
            deep: true
        },
    }
}
</script>